import * as THREE from 'three'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import Experience from '../Experience.js'

export default class Text
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        this.setMeshes()
    }

    setMeshes()
    {
        const snack2TextGeo = new TextGeometry(
            'Press [shift] to use',
            {
                font: this.resources.items.typeface,
                size: 0.5,
                height: 0.2,
                curveSegments: 6,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02
            }
        )
        const snack3TextGeo = new TextGeometry(
            'Press [space] to use',
            {
                font: this.resources.items.typeface,
                size: 0.5,
                height: 0.2,
                curveSegments: 6,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02
            }
        )
        this.scoreTextGeo = new TextGeometry(
            'Score: 0',
            {
                font: this.resources.items.typeface,
                size: 0.75,
                height: 0.2,
                curveSegments: 6,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02
            }
        )
        this.snack2Text = new THREE.Mesh(snack2TextGeo, new THREE.MeshNormalMaterial())
        this.snack2Text.position.set(this.experience.gridSize / 2 - 8, 1.5, - this.experience.gridSize / 2 - 1)
        this.snack2Text.visible = false
        this.snack3Text = new THREE.Mesh(snack3TextGeo, new THREE.MeshNormalMaterial())
        this.snack3Text.position.set(this.experience.gridSize / 2 - 8, .5, - this.experience.gridSize / 2 - 1)
        this.snack3Text.visible = false
        this.scoreTextMat = new THREE.MeshNormalMaterial()
        this.scoreText = new THREE.Mesh(this.scoreTextGeo, this.scoreTextMat)
        this.scoreText.position.set(- this.experience.gridSize / 2, .5, - this.experience.gridSize / 2 - 1)
        this.scene.add(this.scoreText)
        this.scene.add(this.snack2Text)
        this.scene.add(this.snack3Text)

        const gameOverGeo = new TextGeometry(
            'GAME OVER',
            {
                font: this.resources.items.typeface,
                size: 2,
                height: 0.3,
                curveSegments: 6,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02
            }
        )
        gameOverGeo.center();
        this.gameOverText = new THREE.Mesh(gameOverGeo, new THREE.MeshNormalMaterial())
        this.gameOverText.rotation.x = - Math.PI / 2
        this.gameOverText.position.set(0, 3, 2)
        this.scene.add(this.gameOverText)
        this.gameOverText.visible = false
    }

    setGameOverScoreText(newWeekly, newAlltime, weeklyScore, alltimeScore) {
        let text = `Your Score is ${this.experience.counter}`

        if(!newWeekly && !newAlltime) {
            if(this.experience.counter < 5) {
                text = text + `. Maybe next time.`
            } else if(this.experience.counter < 20) {
                text = `Nice. ` + text + '!'
            } else if(this.experience.counter < 40) {
                text = `Good job! ` + text + '!'
            } else if(this.experience.counter < 80) {
                text = `Awesome! ` + text + '!'
            } else if(this.experience.counter > 80) {
                text = `Unreal! ` + text + '!'
            }
        } else {
            if(newWeekly && !newAlltime) text = text + '! You set a new weekly high score!'
            if(newAlltime) text = text + '! You set a new all time high score!'
        }

        const highScoreText = `This weeks high score: ${weeklyScore}  /  All time high score: ${alltimeScore}`

        this.gameOverScoreTextGeo = new TextGeometry(
            text,
            {
                font: this.resources.items.typeface,
                size: .5,
                height: 0.1,
                curveSegments: 6,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02
            }
        )
        this.gameOverHighScoreTextGeo = new TextGeometry(
            highScoreText,
            {
                font: this.resources.items.typeface,
                size: .3,
                height: 0.1,
                curveSegments: 6,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02
            }
        )

        this.gameOverScoreTextGeo.center();
        this.gameOverHighScoreTextGeo.center();
        this.gameOverScoreText = new THREE.Mesh(this.gameOverScoreTextGeo, new THREE.MeshNormalMaterial())
        this.gameOverHighScoreText = new THREE.Mesh(this.gameOverHighScoreTextGeo, new THREE.MeshNormalMaterial())
        this.gameOverHighScoreText.rotation.x = - Math.PI / 2
        this.gameOverHighScoreText.position.set(0, 3, 5)
        this.gameOverScoreText.rotation.x = - Math.PI / 2
        this.gameOverScoreText.position.set(0, 3, 4)
        this.scene.add(this.gameOverScoreText)
        this.scene.add(this.gameOverHighScoreText)
    }
    disposeGameOverScoreText() {
        this.scene.remove(this.gameOverScoreText)
        this.scene.remove(this.gameOverHighScoreText)
        this.gameOverScoreTextGeo.dispose()
        this.gameOverHighScoreTextGeo.dispose()
    }
}