import * as THREE from 'three'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import Experience from '../Experience.js'

export default class Snake
{
    constructor()
    {
        this.experience = new Experience()
        this.count = this.experience.count
        this.active = this.experience.active
        this.scene = this.experience.scene
        this.debug = this.experience.debug
        this.resources = this.experience.resources
        this.audio = null

        this.target = {
            x: 0,
            z: 0
        }
        this.targetOffset = (this.experience.gridSize - !this.experience.gridSizeIsEven) / 2
        this.targetTileArrayIndex = {
            x: this.targetOffset,
            z: this.targetOffset
        }
        this.targetHistory = []

        this.snackPresent = false
        this.snack2Present = false
        this.snack2Stored = false
        this.snack3Present = false
        this.snack3Stored = false

        // Snake wave movement params
        this.waveOffset = .5
        this.waveAmp = .14
        this.waveFreq = 5

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('Snake')
        }

        // Debug
        if(this.debug.active)
        {
            this.debugFolder
                .add(this, 'waveOffset')
                .min(0).max(2).step(0.01)
                .name('waveOffset')
            this.debugFolder
                .add(this, 'waveAmp')
                .min(0.01).max(1).step(0.01)
                .name('waveAmp')
            this.debugFolder
                .add(this, 'waveFreq')
                .min(1).max(10).step(0.1)
                .name('waveFreq')
        }

        this.setMeshes()
    }

    setMeshes()
    {
        // Dummy Object to set positions of InstancedMeshes
        this.dummy = new THREE.Object3D()
        this.dummyMatrix = new THREE.Matrix4()

        this.snakeHead = new THREE.Group
        this.snakeHeadEyes = new THREE.Group
        this.snakeHeadLeftEyeG = new THREE.Group
        this.snakeHeadLeftEyeG.position.set(.2, -.45, .175)
        this.snakeHeadRightEyeG = new THREE.Group
        this.snakeHeadRightEyeG.position.set(.2, -.45, -.175)

        // Head
        this.snakeHeadBase = new THREE.Mesh(
            new THREE.SphereGeometry(0.5, 16, 16),
            new THREE.MeshStandardMaterial({
                normalMap: this.resources.items.skinNormal,
                roughnessMap: this.resources.items.skinRoughness,
                aoMap: this.resources.items.skinAo,
                aoMapIntensity: .7,
                color: new THREE.Color('hsl(267, 85%, 44%)'),
                roughness: .5,
                transparent: true
            })
        )
        this.snakeHeadLeftEye = new THREE.Mesh(
            new THREE.SphereGeometry(.125, 24, 24),
            new THREE.MeshStandardMaterial({
                map: this.resources.items.eyeBase,
                color: 0xffffff
            })
        )
        this.snakeHeadLeftEye.rotation.y = - Math.PI / 2
        this.snakeHeadLeftEye.rotation.z = - Math.PI / 2
        this.snakeHeadLeftEyeG.add(this.snakeHeadLeftEye)
        this.snakeHeadEyes.add(this.snakeHeadLeftEyeG)
        this.snakeHeadRightEye = new THREE.Mesh(
            new THREE.SphereGeometry(.125, 24, 24),
            new THREE.MeshStandardMaterial({
                map: this.resources.items.eyeBase,
                color: 0xffffff
            })
        )
        this.snakeHeadRightEye.rotation.y = - Math.PI / 2
        this.snakeHeadRightEye.rotation.z = - Math.PI / 2
        this.snakeHeadRightEyeG.add(this.snakeHeadRightEye)
        this.snakeHeadEyes.add(this.snakeHeadRightEyeG)
        this.snakeHead.add(this.snakeHeadBase)
        this.snakeHead.add(this.snakeHeadEyes)
        this.snakeHead.position.y = .5
        this.snakeHead.rotation.z = Math.PI / 2

        this.baseX = this.snakeHeadBase.position.x
        this.baseY = this.snakeHeadBase.position.y
        this.baseZ = this.snakeHeadBase.position.z

        this.headH = 267 // hsl(267, 77%, 46%)
        this.bodyH = 252 // hsl(252, 69%, 40%)
        this.connectorH = 18 // hsl(18, 100%, 54%)

        // Body
        this.snakeBodyGeometry = new THREE.SphereGeometry(.5, 16, 16)
        this.snakeBodyMaterial = new THREE.MeshStandardMaterial({
            // map: this.resources.items.skinBase,
            normalMap: this.resources.items.skinNormal,
            roughnessMap: this.resources.items.skinRoughness,
            aoMap: this.resources.items.skinAo,
            // aoMapIntensity: .7,
            // displacementMap: this.resources.items.skinHeight,
            // displacementScale: .1,
            color: new THREE.Color('hsl(252, 75%, 38%)'),
            roughness: .5,
            opacity: 1,
            wireframe: false,
            transparent: true
        })
        this.snakeBody = new THREE.InstancedMesh(this.snakeBodyGeometry, this.snakeBodyMaterial, this.count)
        this.snakeBody.instanceMatrix.setUsage( THREE.DynamicDrawUsage )

        // Connectors
        this.snakeBodyConnectorGeometry = new THREE.SphereGeometry(.3, 12, 12)
        this.snakeBodyConnectorMaterial = new THREE.MeshStandardMaterial({
            normalMap: this.resources.items.skinNormal,
            roughnessMap: this.resources.items.skinRoughness,
            aoMap: this.resources.items.skinAo,
            aoMapIntensity: .7,
            color: new THREE.Color('hsl(21, 100%, 54%)'),
            roughness: 0.5,
            transparent: true
        });
        this.snakeBodyConnectors = new THREE.InstancedMesh(this.snakeBodyConnectorGeometry, this.snakeBodyConnectorMaterial, this.count)
        this.snakeBodyConnectors.instanceMatrix.setUsage( THREE.DynamicDrawUsage )

        this.snakeBody.count = 4
        this.snakeBodyConnectors.count = 4

        for (let i = 0; i < this.active; i++) {
            this.dummy.position.set(this.baseX, this.baseY, this.baseZ)
            this.dummy.rotation.z = Math.PI / 2
            this.dummy.updateMatrix()
            this.snakeBody.setMatrixAt( i, this.dummy.matrix )
            this.snakeBodyConnectors.setMatrixAt( i, this.dummy.matrix )
        }

        // Snack
        this.snakeSnack = new THREE.Mesh(
            new THREE.SphereGeometry(0.3, 6, 6),
            new THREE.MeshStandardMaterial({ color: 0x3300ff, roughness: 0.3, emissive: 0x962eff, emissiveIntensity: .5 })
        )
        this.snakeSnack.position.y = .3
        this.snakeSnack.visible = false

        this.scene.add(this.snakeHead)
        this.scene.add(this.snakeBody)
        this.scene.add(this.snakeBodyConnectors)
        this.scene.add(this.snakeSnack)

        // Snack
        this.snakeSnack2 = new THREE.Mesh(
            new THREE.CylinderGeometry(.3, .3, .1),
            new THREE.MeshStandardMaterial({ color: 0xf34949, roughness: 0.3, emissive: 0xf09328, emissiveIntensity: .5 })
        )
        this.snakeSnack2.rotation.x = - Math.PI * 0.5
        this.snakeSnack2.position.y = .3
        this.snakeSnack2.visible = false
        this.scene.add(this.snakeSnack2)
        // Snack
        this.snakeSnack3 = new THREE.Mesh(
            new THREE.CylinderGeometry(.3, .3, .1),
            new THREE.MeshStandardMaterial({ color: 0x001adb, roughness: 0.3, emissive: 0x30dbfd, emissiveIntensity: .5 })
        )
        this.snakeSnack3.rotation.x = - Math.PI * 0.5
        this.snakeSnack3.position.y = .3
        this.snakeSnack3.visible = false
        this.scene.add(this.snakeSnack3)

        // Debug
        if(this.debug.active)
        {
            this.debugFolder
                .addColor(this.snakeHeadBase.material, 'color')
                .name('snakeHeadColor')
            this.debugFolder
                .addColor(this.snakeBody.material, 'color')
                .name('snakeBodyColor')
            this.debugFolder
                .addColor(this.snakeBodyConnectors.material, 'color')
                .name('smallBodyColor')
            this.debugFolder
                .addColor(this.snakeSnack.material, 'color')
                .name('snack1Color')
            this.debugFolder
                .addColor(this.snakeSnack.material, 'emissive')
                .name('snack1Emissive')
            this.debugFolder
                .addColor(this.snakeSnack2.material, 'color')
                .name('snack2Color')
            this.debugFolder
                .addColor(this.snakeSnack2.material, 'emissive')
                .name('snack2Emissive')
            this.debugFolder
                .addColor(this.snakeSnack3.material, 'color')
                .name('snack3Color')
            this.debugFolder
                .addColor(this.snakeSnack3.material, 'emissive')
                .name('snack3Emissive')
        }
    }

    setInitState() {
        this.target = {
            x: 0,
            z: 0
        }

        this.snackPresent = false
        this.snack2Present = false
        this.snack2Stored = false
        this.snack3Present = false
        this.snack3Stored = false

        this.setScore(0)

        this.moveToTarget(this.snakeHead, 'x', this.target, this.target)
        this.moveToTarget(this.snakeHead, 'z', this.target, this.target)

        this.snakeSnack.position.x = 200
        this.snakeSnack2.position.x = 200
        this.snakeSnack3.position.x = 200
        this.snakeSnack.visible = false
        this.snakeSnack2.visible = false
        this.snakeSnack3.visible = false

        this.targetHistory = []
        //this.targetHistory.unshift({x: this.target.x - 6, z: this.target.z})
        this.targetHistory.unshift({x: this.target.x - 5, z: this.target.z})
        this.targetHistory.unshift({x: this.target.x - 4, z: this.target.z})
        this.targetHistory.unshift({x: this.target.x - 3, z: this.target.z})
        this.targetHistory.unshift({x: this.target.x - 2, z: this.target.z})
        this.targetHistory.unshift({x: this.target.x - 1, z: this.target.z})
        this.targetHistory.unshift({x: this.target.x, z: this.target.z})
    }

    updateTick()
    {

    }

    moveToTarget(mesh, axis, position, target, sound) {
        // Check if distance to target is 1
        // otherwise mesh needs to be teleported across the board
        if(Math.abs(target[axis] - position[axis]) <= 1 ) {
            mesh.position[axis] = position[axis] + ((target[axis] - position[axis]) * this.experience.time.progress)
            // if(axis === 'z') {
            //     mesh.rotation['y'] = Math.PI / 2
            // } else {
            //     mesh.rotation['y'] = 0
            // }
        } else {
            if(sound) {
                this.experience.audio.warp.stop()
                this.experience.audio.warp.play()
            }
            // Move mesh half a tile outside of board, then teleport to other side half a tile outside, then move to target tile
            // check if target is positive or negative (center of board is 0)
            if(this.experience.time.progress < .5) {
                mesh.position[axis] = position[axis] - target[axis] / Math.abs(target[axis]) * this.experience.time.progress
            } else {
                mesh.position[axis] = target[axis] + target[axis] / Math.abs(target[axis]) * (1 - this.experience.time.progress)
            }
        }
    }

    setScore(amount) {
        // Add Body Part / Increase Counter
        this.snakeBody.count = amount + 4
        this.snakeBodyConnectors.count = amount + 4
        this.experience.active = amount + 4
        this.experience.counter = amount

        // Update scoreText
        this.scene.remove(this.experience.world.text.scoreText)
        this.experience.world.text.scoreTextGeo.dispose()
        this.experience.world.text.scoreTextGeo = new TextGeometry(
            'Score: ' + this.experience.counter,
            {
                font: this.experience.resources.items.typeface,
                size: 0.75,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02
            }
        )
        this.experience.world.text.scoreText = new THREE.Mesh(this.experience.world.text.scoreTextGeo, this.experience.world.text.scoreTextMat)
        this.experience.world.text.scoreText.position.set(- this.experience.gridSize / 2, .5, - this.experience.gridSize / 2 - 1)
        this.scene.add(this.experience.world.text.scoreText)
    }

    update()
    {
        if (!this.experience.resetting) {
            this.snakeSnack.rotation.y = this.experience.time.elapsed
            this.snakeSnack2.rotation.z = - 6 * this.experience.time.elapsed
            this.snakeSnack3.rotation.z = .5 * this.experience.time.elapsed

            this.snakeHeadLeftEyeG.rotation.z = (Math.sin((this.experience.time.elapsed + 3) * 2) * .4)
            this.snakeHeadLeftEyeG.rotation.x = (Math.sin(this.experience.time.elapsed * 2.5) * .4)
            this.snakeHeadRightEyeG.rotation.z = (Math.sin((this.experience.time.elapsed * -1) * 2) * .4)
            this.snakeHeadRightEyeG.rotation.x = (Math.sin((this.experience.time.elapsed - 2) * 2.5) * .4)

            if(!this.experience.gameover) {
                // Snack Logic
                if(!this.experience.idle) {
                    if(this.experience.snack2InUse) {
                        this.headH += 1 // hsl(267, 77%, 46%)
                        if(this.headH > 360) this.headH = 1
                        this.snakeHeadBase.material.color = new THREE.Color('hsl('+ this.headH +', 77%, 46%)')

                        this.bodyH += 1; // hsl(252, 69%, 40%)
                        if(this.bodyH > 360) this.bodyH = 1
                        this.snakeBody.material.color = new THREE.Color('hsl('+ this.bodyH +', 69%, 40%)')

                        this.connectorH += 1 // hsl(18, 100%, 54%)
                        if(this.connectorH > 360) this.connectorH = 1
                        this.snakeBodyConnectors.material.color = new THREE.Color('hsl('+ this.connectorH +', 100%, 54%)')
                    }

                    if(!this.snackPresent) {
                        // Choose random tile
                        const randomX = Math.round(Math.random() * (this.experience.gridSize - 1))
                        const randomZ = Math.round(Math.random() * (this.experience.gridSize - 1))
                        let randomTile = this.experience.world.floor.floorArray[randomX][randomZ]

                        // If tile not occupied, use as new snackTile
                        if(this.experience.world.floor.highlightTiles.getUniformAt('opacity', randomTile) !== 1) {
                            this.experience.world.floor.highlightTiles.getMatrixAt(randomTile, this.experience.world.floor.dummyMatrix)
                            this.experience.world.floor.dummyMatrix.decompose(this.experience.world.floor.dummy.position, this.experience.world.floor.dummy.quaternion, this.experience.world.floor.dummy.scale)
                            this.snakeSnack.position.x = this.experience.world.floor.dummy.position.x
                            this.snakeSnack.position.z = this.experience.world.floor.dummy.position.z
                            this.snakeSnack.visible = true
                            this.experience.world.floor.snackTile = randomTile
                            this.snackPresent = true
                        }
                    } else {
                        if(this.experience.world.floor.highlightTiles.getUniformAt('opacity', this.experience.world.floor.snackTile) === 1) {
                            this.audio.consumed.stop();
                            this.audio.consumed.play(-.2);
                            this.setScore(this.experience.counter + 1)
                            // Hide snakeSnack
                            this.snakeSnack.visible = false
                            this.snackPresent = false
                        }
                    }
                    if(!this.snack2Stored && !this.experience.snack2InUse) {
                        if(!this.snack2Present) {
                            // Choose random tile
                            const randomX = Math.round(Math.random() * (this.experience.gridSize - 1))
                            const randomZ = Math.round(Math.random() * (this.experience.gridSize - 1))
                            let randomTile = this.experience.world.floor.floorArray[randomX][randomZ]

                            // If tile not occupied, use as new snackTile
                            if(this.experience.world.floor.highlightTiles.getUniformAt('opacity', randomTile) !== 1) {
                                this.experience.world.floor.highlightTiles.getMatrixAt(randomTile, this.experience.world.floor.dummyMatrix)
                                this.experience.world.floor.dummyMatrix.decompose(this.experience.world.floor.dummy.position, this.experience.world.floor.dummy.quaternion, this.experience.world.floor.dummy.scale)
                                this.snakeSnack2.position.x = this.experience.world.floor.dummy.position.x
                                this.snakeSnack2.position.z = this.experience.world.floor.dummy.position.z
                                this.snakeSnack2.position.y = .3
                                this.snakeSnack2.visible = true
                                this.experience.world.floor.snackTile2 = randomTile
                                this.snack2Present = true
                            }
                        } else {
                            if(this.experience.world.floor.highlightTiles.getUniformAt('opacity', this.experience.world.floor.snackTile2) === 1) {
                                this.audio.coin.stop();
                                this.audio.coin.play();
                                this.snack2Present = false
                                this.snack2Stored = true
                                document.getElementById('mobile-a').classList.add('stored')
                            }
                        }
                    } else {
                        if(!this.experience.snack2InUse) this.experience.world.text.snack2Text.visible = true
                        this.snakeSnack2.position.set(this.experience.gridSize / 2 - .5, 1.65, - this.experience.gridSize / 2 - 1)
                    }
                    if(!this.snack3Stored && !this.experience.snack3InUse) {
                        if(!this.snack3Present) {
                            // Choose random tile
                            const randomX = Math.round(Math.random() * (this.experience.gridSize - 1))
                            const randomZ = Math.round(Math.random() * (this.experience.gridSize - 1))
                            let randomTile = this.experience.world.floor.floorArray[randomX][randomZ]

                            // If tile not occupied, use as new snackTile
                            if(this.experience.world.floor.highlightTiles.getUniformAt('opacity', randomTile) !== 1) {
                                this.experience.world.floor.highlightTiles.getMatrixAt(randomTile, this.experience.world.floor.dummyMatrix)
                                this.experience.world.floor.dummyMatrix.decompose(this.experience.world.floor.dummy.position, this.experience.world.floor.dummy.quaternion, this.experience.world.floor.dummy.scale)
                                this.snakeSnack3.position.x = this.experience.world.floor.dummy.position.x
                                this.snakeSnack3.position.z = this.experience.world.floor.dummy.position.z
                                this.snakeSnack3.position.y = .3
                                this.snakeSnack3.visible = true
                                this.experience.world.floor.snackTile3 = randomTile
                                this.snack3Present = true
                            }
                        } else {
                            if(this.experience.world.floor.highlightTiles.getUniformAt('opacity', this.experience.world.floor.snackTile3) === 1) {
                                this.audio.coin.stop();
                                this.audio.coin.play();
                                this.snack3Present = false
                                this.snack3Stored = true
                                document.getElementById('mobile-b').classList.add('stored')
                            }
                        }
                    } else {
                        if(!this.experience.snack3InUse) this.experience.world.text.snack3Text.visible = true
                        this.snakeSnack3.position.set(this.experience.gridSize / 2 - .5, .7, - this.experience.gridSize / 2 - 1)
                    }
                }

                // Snake movement
                if(this.experience.started || this.experience.idle) {

                    if(this.experience.lastKey !== 'y') {

                        const headAxis = this.target.x === this.targetHistory[1].x ? 'z' : 'x'
                        const headDir = this.experience.lastKey === 'a' || this.experience.lastKey === 'ArrowLeft' || this.experience.lastKey === 'w' || this.experience.lastKey === 'ArrowUp' ? 1 : -1

                        // const headWaveAxis = headAxis === 'z' ? 'x' : 'z';
                        const snakeHeadCurrent = this.experience.idle ? this.target : this.targetHistory[1]
                        this.moveToTarget(this.snakeHead, headAxis, snakeHeadCurrent, this.target, true)
                        this.snakeHead.position.y = this.waveOffset + this.waveAmp * Math.sin(this.experience.time.elapsed * this.waveFreq)
                        if(headAxis === 'z') {
                            this.snakeHead.rotation['y'] = headDir * Math.PI / 2
                        } else {
                            if(headDir === -1) {
                                this.snakeHead.rotation['y'] = 0
                            } else {
                                this.snakeHead.rotation['y'] = Math.PI
                            }
                        }
                        // this.snakeHead.position[headWaveAxis] = this.target[headWaveAxis] + (this.waveOffset + this.waveAmp * Math.sin(this.experience.time.elapsed * this.waveFreq));
                        if ( this.snakeBody ) {

                            for (let i = 0; i < this.experience.active; i++) {
                                // if(this.experience.active - this.experience.showing > 1)

                                // shift position of "invisible" new body elements to last visible element
                                let tempI = i
                                if(i > this.experience.showing - 1) tempI = this.experience.showing - 1

                                // index of last new showing body part
                                let selector
                                if(this.experience.showingChanged) selector = this.experience.active - (Math.abs(this.experience.showing - this.experience.active) + 1)

                                if((selector && i === selector)) {
                                    this.snakeBody.getMatrixAt(i, this.dummyMatrix)
                                    this.dummyMatrix.decompose(this.dummy.position, this.dummy.quaternion, this.dummy.scale)
                                    // this.dummy.scale.set(progress, progress, progress);
                                    this.dummy.position.y = this.waveOffset + this.waveAmp * Math.sin((this.experience.time.elapsed + 1 + i) * this.waveFreq)
                                } else {
                                    const segmentAxis = this.targetHistory[tempI + 1].x === this.targetHistory[tempI + 2].x ? 'z' : 'x'
                                    // const segmentWaveAxis = segmentAxis === 'z' ? 'x' : 'z';
                                    // this.dummy.scale.set(1,1,1);
                                    // if distance to next tile is smaller than 2 (i.e. not warping from one side to the other)
                                    const snakeSegmentCurrent = this.experience.idle ? this.targetHistory[tempI + 1] : this.targetHistory[tempI + 2]
                                    if(segmentAxis === 'z') {
                                        this.dummy.rotation['y'] = Math.PI / 2
                                    } else {
                                        this.dummy.rotation['y'] = 0
                                    }
                                    this.moveToTarget(this.dummy, 'x', snakeSegmentCurrent, this.targetHistory[tempI + 1])
                                    this.moveToTarget(this.dummy, 'z', snakeSegmentCurrent, this.targetHistory[tempI + 1])
                                    this.dummy.position.y = this.waveOffset + this.waveAmp * Math.sin((this.experience.time.elapsed + 1 + tempI) * this.waveFreq)
                                }

                                this.dummy.updateMatrix()
                                this.snakeBody.setMatrixAt( i, this.dummy.matrix )

                                const connectorCurr = {}
                                const connectorDest = {}

                                const snakeConnectorCurrent = this.experience.idle ? this.targetHistory[tempI + 2] : this.targetHistory[tempI + 2]
                                const snakeConnectorDest = this.experience.idle ? this.targetHistory[tempI + 1] : this.targetHistory[tempI + 1]

                                connectorCurr.x = (snakeConnectorCurrent.x + (this.targetHistory[tempI + 1].x - snakeConnectorCurrent.x) / 2)
                                connectorDest.x = (snakeConnectorDest.x + (this.targetHistory[tempI].x - snakeConnectorDest.x) / 2)
                                connectorCurr.z = (snakeConnectorCurrent.z + (this.targetHistory[tempI + 1].z - snakeConnectorCurrent.z) / 2)
                                connectorDest.z = (snakeConnectorDest.z + (this.targetHistory[tempI].z - snakeConnectorDest.z) / 2)

                                // const connectorAxis = connectorDest.x === connectorCurr.x ? 'z' : 'x';
                                // const connectorWaveAxis = connectorAxis === 'z' ? 'x' : 'z';

                                const snakeConnectorA = this.experience.idle ? connectorDest : connectorCurr

                                const connectorX = snakeConnectorA.x + ((connectorDest.x - snakeConnectorA.x) * this.experience.time.progress)
                                const connectorZ = snakeConnectorA.z + ((connectorDest.z - snakeConnectorA.z) * this.experience.time.progress)
                                if(Math.abs(connectorDest.x - connectorCurr.x) <= 1) this.dummy.position.x = connectorX
                                // this.moveToTarget(this.dummy, connectorAxis, connectorCurr, connectorDest)
                                this.dummy.position.y = this.waveOffset + this.waveAmp * Math.sin((this.experience.time.elapsed + 1.125 + i) * this.waveFreq)
                                if(Math.abs(connectorDest.z - connectorCurr.z) <= 1) this.dummy.position.z = connectorZ

                                this.dummy.updateMatrix()
                                this.snakeBodyConnectors.setMatrixAt( i, this.dummy.matrix )
                            }

                            this.snakeBody.instanceMatrix.needsUpdate = true
                            this.snakeBody.computeBoundingSphere()
                            this.snakeBodyConnectors.instanceMatrix.needsUpdate = true
                            this.snakeBodyConnectors.computeBoundingSphere()

                        }
                    }
                }
            }
        }
    }
}