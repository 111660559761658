import * as dat from 'lil-gui'
import Experience from '../Experience.js'

export default class Debug
{
    constructor()
    {
        this.experience = new Experience()
        this.active = this.experience.url.searchParams.get("debug") === '1';
        this.nolog = this.experience.url.searchParams.get("nolog") === '1';

        if(this.active)
        {
            this.ui = new dat.GUI()
        }
    }
}