import Experience from '../Experience.js'
import Environment from './Environment.js'
import Floor from './Floor.js'
import Snake from './Snake.js'
import Text from './Text.js'

export default class World
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.text = new Text()
        this.floor = new Floor()
        this.snake = new Snake()
        this.environment = new Environment()
    }

    updateTick()
    {
        this.floor.updateTick()
        this.snake.updateTick()
    }
    update()
    {
        this.floor.update()
        this.snake.update()
    }
}