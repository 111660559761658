import * as THREE from 'three'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import Stats from 'stats-js';
import { gsap } from 'gsap';
import nipplejs from 'nipplejs';

import Debug from './Utils/Debug.js'
import Sizes from './Utils/Sizes.js'
import Time from './Utils/Time.js'
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import Composer from './Composer.js'
import World from './World/World.js'
import Audio from './World/Audio.js'
import Resources from './Utils/Resources.js'


import sources from './sources.js'
import Text from "./World/Text.js";

let instance = null
THREE.ColorManagement.enabled = false

export default class Experience
{
    constructor(_canvas)
    {
        // Singleton
        if(instance)
        {
            return instance
        }
        instance = this

        // Global access
        window.experience = this

        // Options
        this.canvas = _canvas
        this.session = Math.round(Math.random() * 1000000000)

        // Vars
        this.url = new URL(window.location.href)
        this.gridSize = this.url.searchParams.get("gridSize") || 21 // 20 by 20 tiles
        this.gridSizeIsEven = !(this.gridSize % 2)
        this.count = Math.pow(this.gridSize, 2) // maximum number of body parts = number of tiles
        this.lastKey = 'x'
        this.keyInputsSinceLastTick = []
        this.active = 4 // start with 3 body parts active
        this.showingChanged = false // to check if new body part is shown in new tick
        this.showing = 4
        this.counter = 0 // number of snacks eaten
        this.key = 2847
        this.isMobile = /iPhone|iPod|iPad|Android|BlackBerry/.test(navigator.userAgent)
        this.isIOs = /iPhone|iPod|iPad/.test(navigator.userAgent)
        this.canVibrate = 'vibrate' in navigator || 'mozVibrate' in navigator

        if(this.isMobile) {
            document.body.classList.add('isMobile')
            window.scrollTo(0,1)
        }

        if (this.canVibrate && !('vibrate' in navigator)) navigator.vibrate = navigator.mozVibrate

        this.backgroundColor = '#060a1f'

        this.standardSpeed = 10 // 1 = 1s per tile, 10 = 0.1s per tile
        this.speed = 10 // 1 = 1s per tile, 10 = 0.1s per tile
        this.newSpeed = 10 // 1 = 1s per tile, 10 = 0.1s per tile

        this.resetting = false

        this.idle = true
        this.started = false
        this.gameover = false
        this.audioActive = false

        this.snack2InUse = false
        this.snack3InUse = false

        this.cursor = {}
        this.cursor.x = 0
        this.cursor.y = 0

        // Setup
        this.debug = new Debug()

        this.resources = new Resources(sources)

        // Wait for resources
        this.resources.on('ready', () =>
        {
            // Setup
            this.sizes = new Sizes()
            this.time = new Time()
            this.scene = new THREE.Scene()

            this.camera = new Camera()
            this.renderer = new Renderer()
            //this.composer = new Composer()
            this.world = new World()

            this.audio = new Audio()

            this.stats = new Stats()
            this.stats.showPanel(0)
            document.body.appendChild(this.stats.dom)

            this.setKeyListener()
            this.setMouseMoveListener()

            this.world.snake.targetHistory.unshift({x: this.world.snake.target.x - 6, z: this.world.snake.target.z})
            this.world.snake.targetHistory.unshift({x: this.world.snake.target.x - 5, z: this.world.snake.target.z})
            this.world.snake.targetHistory.unshift({x: this.world.snake.target.x - 4, z: this.world.snake.target.z})
            this.world.snake.targetHistory.unshift({x: this.world.snake.target.x - 3, z: this.world.snake.target.z})
            this.world.snake.targetHistory.unshift({x: this.world.snake.target.x - 2, z: this.world.snake.target.z})
            this.world.snake.targetHistory.unshift({x: this.world.snake.target.x - 1, z: this.world.snake.target.z})
            this.world.snake.targetHistory.unshift({x: this.world.snake.target.x, z: this.world.snake.target.z})

            //this.world.floor.tileHistory.unshift(this.world.floor.floorArray[this.world.snake.targetOffset - 6][this.world.snake.targetOffset])
            this.world.floor.tileHistory.unshift(this.world.floor.floorArray[this.world.snake.targetOffset - 5][this.world.snake.targetOffset])
            this.world.floor.tileHistory.unshift(this.world.floor.floorArray[this.world.snake.targetOffset - 4][this.world.snake.targetOffset])
            this.world.floor.activateTile(this.world.floor.floorArray[this.world.snake.targetOffset - 4][this.world.snake.targetOffset])
            this.world.floor.tileHistory.unshift(this.world.floor.floorArray[this.world.snake.targetOffset - 3][this.world.snake.targetOffset])
            this.world.floor.activateTile(this.world.floor.floorArray[this.world.snake.targetOffset - 3][this.world.snake.targetOffset])
            this.world.floor.tileHistory.unshift(this.world.floor.floorArray[this.world.snake.targetOffset - 2][this.world.snake.targetOffset])
            this.world.floor.activateTile(this.world.floor.floorArray[this.world.snake.targetOffset - 2][this.world.snake.targetOffset])
            this.world.floor.tileHistory.unshift(this.world.floor.floorArray[this.world.snake.targetOffset - 1][this.world.snake.targetOffset])
            this.world.floor.activateTile(this.world.floor.floorArray[this.world.snake.targetOffset - 1][this.world.snake.targetOffset])
            this.world.floor.tileHistory.unshift(this.world.floor.floorArray[this.world.snake.targetOffset][this.world.snake.targetOffset])
            this.world.floor.activateTile(this.world.floor.floorArray[this.world.snake.targetOffset][this.world.snake.targetOffset])

            // Debug
            if(this.debug.active)
            {
                const obj = {
                    feed5: () => {
                        this.active += 5
                        this.counter += 5
                        this.world.snake.snakeBody.count += 5
                        this.world.snake.snakeBodyConnectors.count += 5


                        this.scene.remove(this.world.text.scoreText)
                        this.world.text.scoreTextGeo.dispose();
                        this.world.text.scoreTextGeo = new TextGeometry(
                            'Score: ' + this.counter,
                            {
                                font: this.resources.items.typeface,
                                size: 0.75,
                                height: 0.2,
                                curveSegments: 12,
                                bevelEnabled: true,
                                bevelThickness: 0.03,
                                bevelSize: 0.02
                            }
                        )
                        this.world.text.scoreText = new THREE.Mesh(this.world.text.scoreTextGeo, this.world.text.scoreTextMat)
                        this.world.text.scoreText.position.set(- this.gridSize / 2, .5, - this.gridSize / 2 - 1)
                        this.scene.add(this.world.text.scoreText)
                    }
                };

                this.debug.ui.add(obj,'feed5').name('Increase snake length by 5')
            }

            // Resize event
            this.sizes.on('resize', () =>
            {
                this.resize()
            })

            // Time frame event
            this.time.on('frame', () =>
            {
                this.update()
            })
            // Time tick event
            this.time.on('tick', () =>
            {
                this.updateTick()
            })
        })

    }

    resize()
    {
        this.camera.resize()
        this.renderer.resize()
    }

    updateTick()
    {
        if(!this.gameover) {
            this.world.updateTick()

            if(!this.idle) {
                this.keyInputsSinceLastTick = this.keyInputsSinceLastTick.slice(0, 3)
                if(this.keyInputsSinceLastTick.length > 0) {
                    if(this.keyInputsSinceLastTick.length >= 2 && this.lastKey === this.keyInputsSinceLastTick[0]) this.keyInputsSinceLastTick.shift()
                    this.lastKey = this.keyInputsSinceLastTick[0]
                    if(this.keyInputsSinceLastTick.length > 1) this.keyInputsSinceLastTick.shift()
                }

                switch (this.lastKey) {
                    case 'd':
                    case 'ArrowRight':
                        this.world.snake.target.x + 1 === this.world.snake.targetOffset + !this.gridSizeIsEven ? this.world.snake.target.x = -this.world.snake.targetOffset : this.world.snake.target.x++
                        break
                    case 'a':
                    case 'ArrowLeft':
                        this.world.snake.target.x - 1 === -this.world.snake.targetOffset - !this.gridSizeIsEven ? this.world.snake.target.x = this.world.snake.targetOffset : this.world.snake.target.x--
                        break
                    case 'w':
                    case 'ArrowUp':
                        this.world.snake.target.z - 1 === -this.world.snake.targetOffset - !this.gridSizeIsEven ? this.world.snake.target.z = this.world.snake.targetOffset : this.world.snake.target.z--
                        break
                    case 's':
                    case 'ArrowDown':
                        this.world.snake.target.z + 1 === this.world.snake.targetOffset + !this.gridSizeIsEven ? this.world.snake.target.z = -this.world.snake.targetOffset : this.world.snake.target.z++
                        break
                    case 'x':
                        break
                }
                this.world.snake.targetTileArrayIndex.x = this.world.snake.target.x + this.world.snake.targetOffset
                this.world.snake.targetTileArrayIndex.z = this.world.snake.target.z + this.world.snake.targetOffset

                if(this.lastKey !== 'x') {
                    this.world.snake.targetHistory.unshift({x: this.world.snake.target.x, z: this.world.snake.target.z})
                    this.world.floor.tileHistory.unshift(this.world.floor.floorArray[this.world.snake.targetTileArrayIndex.x][this.world.snake.targetTileArrayIndex.z])

                    if(this.showing < this.active) {
                        this.showing++
                        this.showingChanged = true
                    } else {
                        this.showingChanged = false
                    }

                    this.world.snake.targetHistory = this.world.snake.targetHistory.slice(0, this.showing + 6)
                    this.world.floor.tileHistory = this.world.floor.tileHistory.slice(0, this.showing + 6)

                    if(!this.started) this.started = true
                }
            }
        }
    }

    setStarted() {
        gsap.fromTo(this.world.floor, { floorScale: 1 }, { duration: 1, floorScale: 0.95, onUpdate: () => {
                let tempIndex = 0
                for (let x = 0; x < this.gridSize; x++) {
                    for (let z = 0; z < this.gridSize; z++) {
                        this.world.floor.stencilTiles.getMatrixAt(tempIndex, this.world.floor.dummyMatrix)
                        this.world.floor.dummyMatrix.decompose(this.world.floor.dummy.position, this.world.floor.dummy.quaternion, this.world.floor.dummy.scale)
                        this.world.floor.dummy.scale.set(this.world.floor.floorScale, this.world.floor.floorScale, this.world.floor.floorScale)

                        this.world.floor.dummy.updateMatrix()
                        this.world.floor.stencilTiles.setMatrixAt( tempIndex, this.world.floor.dummy.matrix )

                        tempIndex++
                    }
                }
                this.world.floor.stencilTiles.instanceMatrix.needsUpdate = true
                this.world.floor.stencilTiles.computeBoundingSphere()
            } })

        setTimeout(() => {
            gsap.to(this.camera.instance.position, { duration: 2, x:this.isMobile ? this.camera.mobileStartCameraPosition.x : this.camera.desktopStartCameraPosition.x, y:this.isMobile ? this.camera.mobileStartCameraPosition.y : this.camera.desktopStartCameraPosition.y, z:this.isMobile ? this.camera.mobileStartCameraPosition.z : this.camera.desktopStartCameraPosition.z })
            this.idle = false
        },300)

        if(this.audioActive) {
            this.audio.musicStart.play()

            let temp = {value: 0}
            gsap.to(temp,
                {
                    duration: 1,
                    value: 0.02 * this.audio.musicMasterVolume,
                    ease: 'none',
                    onUpdate: () => {
                        this.audio.musicStart.setVolume( temp.value )
                    }
                })

            setTimeout(() => {
                this.audio.musicLoop.play()
            }, this.audio.musicStart.buffer.duration * 1000)
        }

        document.body.classList.add('out')
    }

    update()
    {
        this.stats.begin()
        this.camera.update()
        this.world.update()
        this.renderer.update()
        //this.composer.update()

        this.stats.end()
    }

    reset() {
        this.resetting = true

        this.idle = true
        this.started = false
        this.gameover = false
        this.lastKey = 'x'
        this.keyInputsSinceLastTick = []
        this.active = 4 // start with 3 body parts active
        this.showingChanged = false // to check if new body part is shown in new tick
        this.showing = 4
        this.key = 2847

        this.world.snake.setInitState()
        this.world.floor.setInitState()

        this.world.text.snack2Text.visible = false
        this.world.text.snack3Text.visible = false

        gsap.to(this.camera.instance.position, { duration: 2, x: this.camera.initialCameraPosition.x, y: this.camera.initialCameraPosition.y, z: this.camera.initialCameraPosition.z })

        document.body.classList.remove('out')

        this.resetting = false
    }

    getOppositeDirection(key) {
        if(key === 'w') return 's'
        if(key === 'ArrowUp') return 'ArrowDown'
        if(key === 's') return 'w'
        if(key === 'ArrowDown') return 'ArrowUp'
        if(key === 'a') return 'd'
        if(key === 'ArrowLeft') return 'ArrowRight'
        if(key === 'd') return 'a'
        if(key === 'ArrowRight') return 'ArrowLeft'
    }

    handleKeyInput(key) {
        if(key === 'w' || key === 'a' || key === 's' || key === 'd' || key === 'ArrowUp' || key === 'ArrowLeft' || key === 'ArrowDown' || key === 'ArrowRight') {
            if(this.idle) {
                if(key === 'a' || key === 'ArrowLeft' || !document.body.classList.contains('started')) return
                this.setStarted()
            }
            if(!(this.keyInputsSinceLastTick[this.keyInputsSinceLastTick.length - 1] === key) && !(this.keyInputsSinceLastTick[this.keyInputsSinceLastTick.length - 1] === this.getOppositeDirection(key))) {
                if(this.isMobile) navigator.vibrate(50)
                this.keyInputsSinceLastTick.push(key)
            }
        }
        if(key === 'Shift') {
            if(this.world.snake.snack2Stored && !this.snack2InUse && !this.snack3InUse && !this.gameover) {
                let temp = {value: 1}
                gsap.to(temp,
                    {
                        duration: 1,
                        value: 1.5,
                        onUpdate: () => {
                            this.audio.musicLoop.setPlaybackRate(temp.value)
                            this.audio.consumed.setPlaybackRate(temp.value)
                            this.audio.coin.setPlaybackRate(temp.value)
                            this.audio.tick.setPlaybackRate(temp.value)
                            this.audio.warp.setPlaybackRate(temp.value)
                        }
                    })
                this.snack2InUse = true
                this.world.snake.snack2Stored = false
                this.world.snake.snakeSnack2.visible = false
                this.world.text.snack2Text.visible = false
                this.newSpeed = 20
                this.world.environment.ambientLight.intensity = 0
                this.world.floor.starSpeed = 0.2

                // let temp2 = {value: 75}
                // gsap.to(temp2,
                //     {
                //         duration: 1,
                //         value: 85,
                //         onUpdate: () => {
                //             this.camera.instance.fov = temp2.value
                //             this.camera.instance.updateProjectionMatrix()
                //         }
                //     })
            }
        }
        if(key === ' ') {
            if(this.world.snake.snack3Stored && !this.snack2InUse && !this.snack3InUse && !this.gameover) {
                let temp = {value: 1}
                gsap.to(temp,
                    {
                        duration: 1,
                        value: 0.5,
                        onUpdate: () => {
                            this.audio.musicLoop.setPlaybackRate(temp.value)
                            this.audio.consumed.setPlaybackRate(temp.value)
                            this.audio.coin.setPlaybackRate(temp.value)
                            this.audio.tick.setPlaybackRate(temp.value)
                            this.audio.warp.setPlaybackRate(temp.value)
                        }
                    })
                this.snack3InUse = true
                this.world.snake.snack3Stored = false
                this.world.snake.snakeSnack3.visible = false
                this.world.text.snack3Text.visible = false
                this.newSpeed = 2
                this.world.floor.starSpeed = 0.003
                this.world.environment.ambientLight.intensity = 1
                this.world.environment.ambientLight.color = new THREE.Color(0x001eff)
                this.world.snake.snakeHeadBase.material.wireframe = true
                this.world.snake.snakeBody.material.wireframe = true
                this.world.snake.snakeBodyConnectors.material.wireframe = true

                // let temp2 = {value: 75}
                // gsap.to(temp2,
                //     {
                //         duration: 1,
                //         value: 50,
                //         onUpdate: () => {
                //             this.camera.instance.fov = temp2.value
                //             this.camera.instance.updateProjectionMatrix()
                //         }
                //     })
            }
        }
        // if(key === 'x') {
        //     this.lastKey = key
        // }
        // if(key === 'm') {
        //     this.newSpeed += 1
        // }
    }

    setKeyListener()
    {
        window.addEventListener('keydown', (event) => {
            this.handleKeyInput(event.key)
        })
    }
    setMouseMoveListener()
    {
        window.addEventListener('mousemove', (event) =>
        {
            this.cursor.x = event.clientX / this.sizes.width - 0.5
            this.cursor.y = event.clientY / this.sizes.height - 0.5
        })
        document.getElementById('startbtn').addEventListener('click', () => {
            this.keyInputsSinceLastTick.push('d')
            this.audio.start.play()
            this.setStarted()
            document.getElementById('startbtn').blur()
        })
        document.getElementById('reset').addEventListener('click', () => {
            document.body.classList.remove('gameover')
            this.reset()
            this.joystick[0].el.style.top = '48px'
            this.joystick[0].el.style.left = '48px'
            document.getElementById('reset').blur()
        })
        document.getElementById('continue').addEventListener('click', () => {
            if(this.isMobile && !this.isIOs) document.documentElement.requestFullscreen()
            document.body.classList.add('started')
            this.audioActive = true
            this.audio.ambient.play()
            this.audio.changeMusicVolume(0, 1)
            this.audio.changeUiVolume(0, 1)
            document.getElementById('ui-control').classList.remove('disabled')
            document.getElementById('music-control').classList.remove('disabled')
            document.getElementById('continue').blur()
        })
        document.getElementById('noaudio').addEventListener('click', () => {
            if(this.isMobile && !this.isIOs) document.documentElement.requestFullscreen()
            document.body.classList.add('started')
            this.audioActive = true
            this.audio.ambient.play()
            document.getElementById('noaudio').blur()
        })
        document.getElementById('music-control').addEventListener('click', () => {
            if(document.getElementById('music-control').classList.contains('disabled')) {
                document.getElementById('music-control').classList.remove('disabled')
                this.audio.changeMusicVolume(0, 1)
            } else {
                document.getElementById('music-control').classList.add('disabled')
                this.audio.changeMusicVolume(1, 0)
            }

            document.getElementById('music-control').blur()
        })
        document.getElementById('ui-control').addEventListener('click', () => {
            if(document.getElementById('ui-control').classList.contains('disabled')) {
                document.getElementById('ui-control').classList.remove('disabled')
                this.audio.changeUiVolume(0, 1)
            } else {
                document.getElementById('ui-control').classList.add('disabled')
                this.audio.changeUiVolume(1, 0)
            }

            document.getElementById('ui-control').blur()
        })
        document.getElementById('mobile-a').addEventListener('click', () => {
            navigator.vibrate(50)
            this.handleKeyInput('Shift')
            document.getElementById('mobile-a').classList.remove('stored')
            document.getElementById('mobile-a').blur()
        })
        document.getElementById('mobile-b').addEventListener('click', () => {
            navigator.vibrate(50)
            this.handleKeyInput(' ')
            document.getElementById('mobile-b').classList.remove('stored')
            document.getElementById('mobile-b').blur()
        })


        const options = {
            zone: document.getElementById('zone_joystick'),
            // color: String,
            size: 96,
            // threshold: Float,               // before triggering a directional event
            // fadeTime: Integer,              // transition time
            // multitouch: Boolean,
            // maxNumberOfNipples: Number,     // when multitouch, what is too many?
            // dataOnly: Boolean,              // no dom element whatsoever
            // position: Object,               // preset position for 'static' mode
            mode: 'static',                   // 'dynamic', 'static' or 'semi'
            // restJoystick: Boolean|Object,   // Re-center joystick on rest state
            // restOpacity: Number,            // opacity when not 'dynamic' and rested
            // lockX: Boolean,                 // only move on the X axis
            // lockY: Boolean,                 // only move on the Y axis
            // catchDistance: Number,          // distance to recycle previous joystick in
            //                                 // 'semi' mode
            // shape: String,                  // 'circle' or 'square'
            dynamicPage: true,           // Enable if the page has dynamically visible elements
            follow: true,                // Makes the joystick follow the thumbstick
        };
        this.joystick = nipplejs.create(options)
        this.joystick.on('dir:up dir:left dir:down dir:right',
            (evt, data) => {
                if(evt.type === 'dir:up') this.handleKeyInput('w')
                if(evt.type === 'dir:left') this.handleKeyInput('a')
                if(evt.type === 'dir:down') this.handleKeyInput('s')
                if(evt.type === 'dir:right') this.handleKeyInput('d')
            })
    }

    destroy()
    {
        this.sizes.off('resize')
        this.time.off('tick')

        // Traverse the whole scene
        this.scene.traverse((child) =>
        {
            // Test if it's a mesh
            if(child instanceof THREE.Mesh)
            {
                child.geometry.dispose()

                // Loop through the material properties
                for(const key in child.material)
                {
                    const value = child.material[key]

                    // Test if there is a dispose function
                    if(value && typeof value.dispose === 'function')
                    {
                        value.dispose()
                    }
                }
            }
        })

        this.camera.controls.dispose()
        this.renderer.instance.dispose()

        if(this.debug.active)
            this.debug.ui.destroy()
    }
}