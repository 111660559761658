import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Environment
{
    constructor()
    {
        this.experience = new Experience()
        this.resources = this.experience.resources
        this.scene = this.experience.scene
        this.debug = this.experience.debug
        
        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('Lights')
        }

        this.setAmbientLight()
        this.setSpotLight()
        this.setHemisphereLight()
        this.setEnvironmentMap()
    }

    setAmbientLight()
    {
        this.ambientLight = new THREE.AmbientLight('#ffffff', .25)
        this.scene.add( this.ambientLight)

        // Debug
        if(this.debug.active)
        {
            this.debugFolder
                .addColor(this.ambientLight, 'color')
                .name('ambientLightColor')
            
            this.debugFolder
                .add(this.ambientLight, 'intensity')
                .name('ambientLightIntensity')
                .min(0)
                .max(2)
                .step(0.001)
        }
    }
    setEnvironmentMap()
    {
        this.environmentMap = {}
        this.environmentMap.texture = this.resources.items.environmentMapTexture
        this.environmentMap.texture.colorSpace = THREE.LinearSRGBColorSpace

        this.scene.background = this.environmentMap.texture
        this.scene.backgroundBlurriness = .1
    }
    setSpotLight()
    {
        this.spotLight = new THREE.SpotLight('#ff8800', 2, 56, Math.PI/4, .5, .35)
        this.spotLight.target.position.set(0, 0, 0)
        this.spotLight.position.set(0, 20, -8)
        // this.spotLight.castShadow = true;
        // this.spotLight.shadow.mapSize.width = 1024;
        // this.spotLight.shadow.mapSize.height = 1024;
        // this.spotLight.shadow.camera.near = 10;
        // this.spotLight.shadow.camera.far = 30;
        // this.spotLight.shadow.camera.fov = 55;

        this.spotLightCameraHelper = new THREE.CameraHelper( this.spotLight.shadow.camera )
        this.scene.add(this.spotLightCameraHelper )
        this.scene.add(this.spotLight)
        this.scene.add(this.spotLight.target)

        this.spotLightHelper = new THREE.SpotLightHelper(this.spotLight)
        this.scene.add(this.spotLightHelper)

        this.spotLightCameraHelper.visible = false
        this.spotLightHelper.visible = false

        // Debug
        if(this.debug.active)
        {
            this.debugFolder
                .addColor(this.spotLight, 'color')
                .name('spotLightColor')

            this.debugFolder
                .add(this.spotLight, 'intensity')
                .name('spotLightIntensity')
                .min(0)
                .max(3)
                .step(0.001)
            this.debugFolder
                .add(this.spotLight, 'distance')
                .name('spotLightDistance')
                .min(20)
                .max(60)
                .step(1)
            this.debugFolder
                .add(this.spotLight, 'decay')
                .name('spotLightDecay')
                .min(0)
                .max(10)
                .step(0.1)
            this.debugFolder
                .add(this.spotLight, 'penumbra')
                .name('spotLightDecay')
                .min(0)
                .max(1)
                .step(0.01)

            this.debugFolder
                .add(this.spotLightCameraHelper, 'visible')
                .name('spotLightCameraHelper')
            this.debugFolder
                .add(this.spotLightHelper, 'visible')
                .name('spotLightHelper')
        }
    }
    setHemisphereLight()
    {
        this.hemisphereLight = new THREE.HemisphereLight( 0xffffbb, 0xb35000, 1 )
        this.scene.add(this.hemisphereLight)

        // Debug
        if(this.debug.active)
        {
            this.debugFolder
                .addColor(this.hemisphereLight, 'color')
                .name('hemiLightColor')

            this.debugFolder
                .addColor(this.hemisphereLight, 'groundColor')
                .name('hemiLightGroundColor')

            this.debugFolder
                .add(this.hemisphereLight, 'intensity')
                .name('hemiLightIntensity')
                .min(0)
                .max(1)
                .step(0.001)
        }
    }
}