import * as THREE from 'three'
import Experience from './Experience.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default class Camera
{
    constructor()
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.initialCameraPosition = new THREE.Vector3(3.219, 1.211, 1.452)
        this.desktopStartCameraPosition = new THREE.Vector3(0, 15, 10)
        this.mobileStartCameraPosition = new THREE.Vector3(0, 30, 20)

        this.setInstance()
        this.setControls()
    }

    setInstance()
    {
        this.group = new THREE.Group
        this.instance = new THREE.PerspectiveCamera(75, this.sizes.width / this.sizes.height, 0.1, 100)
        this.instance.position.set(this.initialCameraPosition.x, this.initialCameraPosition.y, this.initialCameraPosition.z)
        this.group.add(this.instance)
        this.scene.add(this.group)
    }

    setControls()
    {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
        this.controls.maxPolarAngle = Math.PI/2.3;
    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update()
    {
        const parallaxX = this.experience.cursor.x * 2
        const parallaxY = - this.experience.cursor.y * 2

        this.group.position.x += (parallaxX - this.group.position.x) * 5 * this.experience.time.delta
        this.group.position.y += (parallaxY - this.group.position.y) * 5 * this.experience.time.delta

        // this.group.position.z -= (parallaxY - this.group.position.y) * 5 * this.experience.time.delta

        //this.instance.fov = 50
        // console.log(this.experience.world.snake.snakeHead.position)
        // this.instance.lookAt(this.experience.world.snake.snakeHead.position.x, this.experience.world.snake.snakeHead.position.y, this.experience.world.snake.snakeHead.position.z)
        // this.instance.updateProjectionMatrix()

        this.controls.update()
    }
}