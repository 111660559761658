import EventEmitter from './EventEmitter.js'
import Experience from '../Experience.js'
import { gsap } from 'gsap';
import * as THREE from "three";

export default class Time extends EventEmitter
{
    constructor()
    {
        super()

        // Setup
        this.experience = new Experience()
        this.start = Date.now() // 16556554000
        this.current = this.start // 16556554now
        this.previous = this.start // 16556554now
        this.elapsed = 0
        this.speedChangeTimeStamp = Date.now()
        this.elapsedSinceSpeedChange = 0
        this.timeToStandardSpeed = 10
        this.delta = 16
        this.currentTick = 0
        this.previousTick = 0
        this.deltaTick = 16
        this.progress = 0
        this.randomSoundTimer = 15
        this.tickLength = 1 / this.experience.speed
        this.countdownSpan = document.querySelector('#countdown span')

        window.requestAnimationFrame(() =>
        {
            this.tick()
        })
    }

    resetAudioSpeed() {
        let temp = {value: this.experience.audio.musicLoop.getPlaybackRate()}
        gsap.to(temp,
            {
                duration: 1,
                value: 1,
                onUpdate: () => {
                    this.experience.audio.musicLoop.setPlaybackRate(temp.value)
                    this.experience.audio.consumed.setPlaybackRate(temp.value)
                    this.experience.audio.coin.setPlaybackRate(temp.value)
                    this.experience.audio.tick.setPlaybackRate(temp.value)
                    this.experience.audio.warp.setPlaybackRate(temp.value)
                }
            })
    }

    tick()
    {
        this.current = Date.now() // 16556554now
        this.delta = (this.current - this.previous) / 1000 // 0.004

        this.elapsed = (this.current - this.start) / 1000 // 2.005
        this.elapsedSinceSpeedChange = (this.current - this.speedChangeTimeStamp) / 1000 // 2.005
        this.currentTick = Math.floor(this.elapsedSinceSpeedChange / this.tickLength) // x
        this.deltaTick = this.elapsedSinceSpeedChange - this.currentTick * this.tickLength // % progress to new full tick
        this.previous = this.current

        this.progress = this.deltaTick / this.tickLength

        if(this.elapsedSinceSpeedChange <= 10 && (this.experience.snack2InUse || this.experience.snack3InUse)) {
            this.countdownSpan.innerHTML = (this.timeToStandardSpeed - this.elapsedSinceSpeedChange).toFixed(1) + ''
        } else {
            this.countdownSpan.innerHTML = ''
        }

        if(this.previousTick !== this.currentTick) {
            this.previousTick = this.currentTick

            if(this.elapsed >= this.randomSoundTimer) {
                this.randomSoundTimer = this.elapsed + Math.random() * 10 + 5
                this.experience.audio['rattle0' + Math.round(Math.random() + 1)].play()
            }

            this.trigger('tick')

            if(this.experience.speed !== this.experience.newSpeed) {

                this.speedChangeTimeStamp = Date.now()
                this.elapsedSinceSpeedChange = 0
                this.currentTick = 0
                this.previousTick = 0

                this.experience.speed = this.experience.newSpeed
                this.tickLength = 1 / this.experience.speed
            }
            if(this.elapsedSinceSpeedChange >= this.timeToStandardSpeed) {
                this.speedChangeTimeStamp = Date.now()
                this.elapsedSinceSpeedChange = 0
                this.currentTick = 0
                this.previousTick = 0

                this.experience.newSpeed = this.experience.standardSpeed
                this.experience.speed = this.experience.standardSpeed
                this.tickLength = 1 / this.experience.speed

                if(this.experience.snack2InUse) {
                    this.resetAudioSpeed()
                    this.experience.snack2InUse = false
                    this.headH = 267 // hsl(267, 85%, 44%)
                    this.experience.world.snake.snakeHeadBase.material.color = new THREE.Color('hsl('+ this.headH +', 85%, 44%)')

                    this.bodyH = 252; // hsl(252, 75%, 38%)
                    this.experience.world.snake.snakeBody.material.color = new THREE.Color('hsl('+ this.bodyH +', 75%, 38%)')

                    this.connectorH = 21 // hsl(21, 100%, 54%)
                    this.experience.world.snake.snakeBodyConnectors.material.color = new THREE.Color('hsl('+ this.connectorH +', 100%, 54%)')

                    this.experience.world.environment.ambientLight.intensity = .25
                    this.experience.world.floor.starSpeed = 0.01
                }
                if(this.experience.snack3InUse) {
                    this.resetAudioSpeed()
                    this.experience.snack3InUse = false
                    this.experience.world.floor.starSpeed = 0.01
                    this.experience.world.environment.ambientLight.intensity = .25
                    this.experience.world.environment.ambientLight.color = new THREE.Color(0xffffff)
                    this.experience.world.snake.snakeHeadBase.material.wireframe = false
                    this.experience.world.snake.snakeBody.material.wireframe = false
                    this.experience.world.snake.snakeBodyConnectors.material.wireframe = false
                }
            }


        }

        this.trigger('frame')

        window.requestAnimationFrame(() =>
        {
            this.tick()
        })
    }
    }