export default [
    {
        name: 'typeface',
        type: 'font',
        path: './fonts/helvetiker_bold.typeface.json'
    },
    {
        name: 'tileAlpha',
        type: 'texture',
        path: './textures/tileAlpha.jpg'
    },
    {
        name: 'skinAo',
        type: 'texture',
        path: './textures/skin_ao.jpg'
    },
    {
        name: 'skinBase',
        type: 'texture',
        path: './textures/skin_base.jpg'
    },
    {
        name: 'skinHeight',
        type: 'texture',
        path: './textures/skin_height.png'
    },
    {
        name: 'skinNormal',
        type: 'texture',
        path: './textures/skin_normal.jpg'
    },
    {
        name: 'skinRoughness',
        type: 'texture',
        path: './textures/skin_roughness.jpg'
    },
    {
        name: 'eyeBase',
        type: 'texture',
        path: './textures/eye_base.jpg'
    },
    {
        name: 'musicstart',
        type: 'audio',
        path: './sounds/musicstart.wav'
    },
    {
        name: 'musicloop',
        type: 'audio',
        path: './sounds/musicloop.wav'
    },
    {
        name: 'consumed',
        type: 'audio',
        path: './sounds/consumed.wav'
    },
    {
        name: 'coin',
        type: 'audio',
        path: './sounds/coin.wav'
    },
    {
        name: 'rattle01',
        type: 'audio',
        path: './sounds/rattle01.wav'
    },
    {
        name: 'rattle02',
        type: 'audio',
        path: './sounds/rattle02.wav'
    },
    {
        name: 'ambient',
        type: 'audio',
        path: './sounds/ambient.wav'
    },
    {
        name: 'gameover',
        type: 'audio',
        path: './sounds/gameover01.wav'
    },
    {
        name: 'tick',
        type: 'audio',
        path: './sounds/tick.wav'
    },
    {
        name: 'warp',
        type: 'audio',
        path: './sounds/warp.wav'
    },
    {
        name: 'start',
        type: 'audio',
        path: './sounds/start.wav'
    },
    {
        name: 'environmentMapTexture',
        type: 'cubeTexture',
        path:
            [
                './textures/environmentMap/pz1.jpg',
                './textures/environmentMap/pz1.jpg',
                './textures/environmentMap/ny1.jpg',
                './textures/environmentMap/ny1.jpg',
                './textures/environmentMap/pz1.jpg',
                './textures/environmentMap/pz1.jpg'
            ]
    }
]