import * as THREE from 'three'
import Experience from '../Experience.js'
import {gsap} from "gsap";

export default class Audio
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.camera = this.experience.camera.instance
        this.resources = this.experience.resources
        this.experience.world.snake.audio = this

        this.uiMasterVolume = 0
        this.musicMasterVolume = 0

        this.musicStartVolume = 0.02
        this.musicLoopVolume = 0.02
        this.ambientVolume = 0.003
        this.consumedVolume = 0.1
        this.coinVolume = 0.1
        this.gameoverVolume = 0.05
        this.tickVolume = 0.003
        this.warpVolume = 0.015
        this.startVolume = 0.015
        this.rattle01Volume = 0.015
        this.rattle02Volume = 0.02

        this.setAudio()
    }

    setAudio()
    {
        this.listener = new THREE.AudioListener()
        this.camera.add( this.listener )

        this.musicStart = new THREE.Audio( this.listener )
        this.musicStart.setBuffer( this.resources.items.musicstart )
        this.musicStart.setVolume( 0 )

        this.musicLoop = new THREE.Audio( this.listener )
        this.musicLoop.setBuffer( this.resources.items.musicloop )
        this.musicLoop.setVolume( 0 )
        this.musicLoop.setLoop( true )

        this.ambient = new THREE.Audio( this.listener )
        this.ambient.setBuffer( this.resources.items.ambient )
        this.ambient.setVolume( 0 )
        this.ambient.setLoop( true )

        this.consumed = new THREE.Audio( this.listener )
        this.consumed.setBuffer( this.resources.items.consumed )
        this.consumed.setVolume( 0 )

        this.coin = new THREE.Audio( this.listener )
        this.coin.setBuffer( this.resources.items.coin )
        this.coin.setVolume( 0 )

        this.gameover = new THREE.Audio( this.listener )
        this.gameover.setBuffer( this.resources.items.gameover )
        this.gameover.setVolume( 0 )

        this.tick = new THREE.Audio( this.listener )
        this.tick.setBuffer( this.resources.items.tick )
        this.tick.setVolume( 0 )

        this.warp = new THREE.Audio( this.listener )
        this.warp.setBuffer( this.resources.items.warp )
        this.warp.setVolume( 0 )

        this.start = new THREE.Audio( this.listener )
        this.start.setBuffer( this.resources.items.start )
        this.start.setVolume( 0 )
        this.start.setPlaybackRate( 1.5 )

        this.rattle01 = new THREE.Audio( this.listener )
        this.rattle01.setBuffer( this.resources.items.rattle01 )
        this.rattle01.setVolume( 0 )

        this.rattle02 = new THREE.Audio( this.listener )
        this.rattle02.setBuffer( this.resources.items.rattle02 )
        this.rattle02.setVolume( 0 )
    }

    changeMusicVolume(from, to) {
        this.musicMasterVolume = from
        gsap.to(this,
            {
                duration: 1,
                musicMasterVolume: to,
                onUpdate: () => {
                    this.musicStart.setVolume( this.musicMasterVolume * this.musicStartVolume)
                    this.musicLoop.setVolume( this.musicMasterVolume * this.musicLoopVolume)
                    this.tick.setVolume( this.uiMasterVolume * this.tickVolume * (this.musicMasterVolume + 1))
                }
            })
    }
    changeUiVolume(from, to) {
        this.uiMasterVolume = from
        gsap.to(this,
            {
                duration: 1,
                uiMasterVolume: to,
                onUpdate: () => {
                    this.ambient.setVolume( this.uiMasterVolume * this.ambientVolume)
                    this.consumed.setVolume( this.uiMasterVolume * this.consumedVolume)
                    this.coin.setVolume( this.uiMasterVolume * this.coinVolume)
                    this.gameover.setVolume( this.uiMasterVolume * this.gameoverVolume)
                    this.tick.setVolume( this.uiMasterVolume * this.tickVolume * (this.musicMasterVolume + 1))
                    this.warp.setVolume( this.uiMasterVolume * this.warpVolume)
                    this.start.setVolume( this.uiMasterVolume * this.startVolume)
                    this.rattle01.setVolume( this.uiMasterVolume * this.rattle01Volume)
                    this.rattle02.setVolume( this.uiMasterVolume * this.rattle02Volume)
                }
            })
    }
}