import * as THREE from 'three'
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import { InstancedUniformsMesh } from 'three-instanced-uniforms-mesh'
import Experience from '../Experience.js'
import {gsap} from "gsap";

export default class Floor
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.gridSize = this.experience.gridSize
        this.count = this.experience.count
        this.floorArray = []
        this.debug = this.experience.debug
        this.size = 21

        this.floorScale = 1

        this.tileHistory = []
        this.targetTile = null
        this.snackTile = null
        this.snackTile2 = null
        this.snackTile3 = null

        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('Floor')
        }

        this.setGeometries()
        this.setMaterials()
        this.setStars()
        this.setMeshes()
    }

    setStars()
    {
        this.starGeo = new THREE.BufferGeometry()
        this.starCount = 5000
        const starSize = 0.05
        this.starPositions = new Float32Array(this.starCount * 3)
        const starColors = new Float32Array(this.starCount * 3)

        this.starSpeed = 0.01

        for (let i = 0; i < this.starCount; i++) {
            const i3 = i * 3

            this.starPositions[i3] = (Math.random() - .5) * this.gridSize * 1.3
            this.starPositions[i3 + 1] = Math.random() * - 4
            this.starPositions[i3 + 2] = (Math.random() - .5) * this.gridSize * 1.3

            const lightness = Math.random() * (75 - 25) + 25
            const saturation = Math.random() * (100 - 50) + 50
            const hue = Math.random() * (220 - 160) + 160
            const baseColor = new THREE.Color('hsl('+ hue + ', '+ saturation + '%, '+ lightness + '%)')

            starColors[i3] = baseColor.r
            starColors[i3 + 1] = baseColor.g
            starColors[i3 + 2] = baseColor.b
        }

        this.starGeo.setAttribute('position', new THREE.BufferAttribute(this.starPositions, 3))
        this.starGeo.setAttribute('color', new THREE.BufferAttribute(starColors, 3))

        this.starMat = new THREE.PointsMaterial({
            vertexColors: true,
            size: starSize,
            sizeAttenuation: true,
            depthWrite: false,
            blending: THREE.AdditiveBlending,
            stencilWrite: true,
            stencilRef: 1,
            stencilFunc: THREE.EqualStencilFunc
        })

        this.stars = new THREE.Points(this.starGeo, this.starMat)
        this.scene.add(this.stars)
    }

    setGeometries()
    {
        this.floorTileGeo = new THREE.PlaneGeometry(1, 1);
    }

    setMaterials()
    {
        this.floorTileColor = new THREE.Color('#0aff81');
        this.floorTileMat = new THREE.MeshStandardMaterial({ color: '#0aff81' });
        this.floorTileMat.transparent = true
        this.floorTileMat.alphaMap = this.resources.items.tileAlpha
        this.floorTileMat.opacity = 0
        this.floorTileMat.emissive = new THREE.Color(0xFF0000)
        this.floorTileMat.emissiveIntensity = 0
        this.floorTileMat.metalness = 0
        this.floorTileMat.diffuse = this.floorTileColor

        this.floorTileStencilMat = new THREE.MeshStandardMaterial({ color: '#000000' })
        this.floorTileStencilMat.depthWrite = false
        this.floorTileStencilMat.stencilWrite = true
        this.floorTileStencilMat.stencilRef = 1
        this.floorTileStencilMat.stencilFunc = THREE.AlwaysStencilFunc
        this.floorTileStencilMat.stencilZPass = THREE.ReplaceStencilOp

        this.stencilFloorMat = new THREE.MeshPhongMaterial( { color: '#090033' })
        this.stencilFloorMat.stencilWrite = true
        this.stencilFloorMat.stencilRef = 1
        this.stencilFloorMat.stencilFunc = THREE.EqualStencilFunc
    }

    setMeshes()
    {
        // Dummy Object to set positions of InstancedMeshes
        this.dummy = new THREE.Object3D()
        this.dummyMatrix = new THREE.Matrix4()

        this.stencilFloor = new THREE.Mesh(new THREE.PlaneGeometry(Math.pow(this.gridSize, 2) / 2, Math.pow(this.gridSize, 2) / 2), this.stencilFloorMat )
        this.stencilFloor.position.y = -4
        this.stencilFloor.rotation.x = - Math.PI * 0.5
        this.scene.add(this.stencilFloor)

        this.stencilTiles = new THREE.InstancedMesh(this.floorTileGeo, this.floorTileStencilMat, this.count)
        this.stencilTiles.instanceMatrix.setUsage( THREE.DynamicDrawUsage )
        this.highlightTile = new THREE.Mesh(this.floorTileGeo, this.floorTileMat)
        this.highlightTiles = new InstancedUniformsMesh(this.highlightTile.geometry, this.highlightTile.material, this.count)

        this.highlightTiles.getUniformAt = function (name, index) {
            const attrs = this.geometry.attributes
            const attrName = `troika_attr_${name}`
            let attr = attrs[attrName]
            if (attr) {
                return attr.getX(index)
            }
        }

        let tempIndex = 0;
        for (let x = 0; x < this.gridSize; x++) {
            this.floorArray.push([])
            for (let z = 0; z < this.gridSize; z++) {

                this.dummy.rotation.x = - Math.PI * 0.5
                this.dummy.position.set(x - (this.gridSize - !this.experience.gridSizeIsEven) / 2, -0.001, z - (this.gridSize - !this.experience.gridSizeIsEven) / 2)
                this.dummy.scale.set(1,1, 1)

                this.dummy.updateMatrix()
                this.stencilTiles.setMatrixAt( tempIndex, this.dummy.matrix )

                this.dummy.scale.set(.95,.95, .95)
                this.dummy.updateMatrix()
                this.highlightTiles.setMatrixAt( tempIndex, this.dummy.matrix )

                this.highlightTiles.setUniformAt('opacity', tempIndex, 0)
                this.highlightTiles.setUniformAt('diffuse', tempIndex, this.floorTileColor)

                this.floorArray[x].push(tempIndex)

                tempIndex++;
            }
        }

        this.targetTile = this.floorArray[(this.experience.gridSize - !this.experience.gridSizeIsEven) / 2][(this.experience.gridSize - !this.experience.gridSizeIsEven) / 2]
        this.scene.add(this.stencilTiles)
        this.scene.add(this.highlightTiles)

        // Debug
        if(this.debug.active)
        {
            this.debugFolder
                .addColor(this.floorTileMat, 'color')
                .name('tileColor').onChange(() => {
            })
            this.debugFolder
                .addColor(this.stencilFloorMat, 'color')
                .name('stencilFloorColor')
            this.debugFolder
                .addColor(this.starMat, 'color')
                .name('starColor')
        }
    }

    activateTile(tile) {
        this.highlightTiles.setUniformAt('opacity', tile, 1)
        if(this.experience.started) {
            this.experience.audio.tick.stop()
            this.experience.audio.tick.play()
        }
    }
    resetTile(tile) {
        this.highlightTiles.setUniformAt('opacity', tile, 0)
    }

    updateTick()
    {
        if(!this.experience.gameover) {
            // End game if occupied tile is reached
            if(this.experience.started) this.targetTile = this.floorArray[this.experience.world.snake.targetTileArrayIndex.x][this.experience.world.snake.targetTileArrayIndex.z]
            if(!this.experience.idle && this.experience.started && this.highlightTiles.getUniformAt('opacity', this.targetTile) === 1 && !this.experience.snack2InUse && (this.size = this.experience.counter + 17)) {
                this.experience.audio.musicLoop.stop()
                this.experience.audio.gameover.play()
                this.highlightTiles.setUniformAt('diffuse', this.targetTile, new THREE.Color(0xFF0000))
                this.experience.gameover = true
                this.experience.world.text.gameOverText.visible = true


                if(!this.experience.debug.nolog) {
                    fetch("/save/save.php", {
                        method: "POST",
                        body: JSON.stringify({
                            s: this.experience.counter,
                            e: new Date(this.experience.time.elapsed * 1000).toISOString().slice(11, 19),
                            d: new Date().toISOString().slice(0, 19).replace('T', ' '),
                            i: window.navigator.userAgent,
                            l: window.location.hostname + window.location.pathname,
                            x: this.experience.session,
                            k: this.size * this.experience.key
                        }),
                        headers: {
                            "Content-type": "application/json; charset=UTF-8"
                        }
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        this.experience.world.text.setGameOverScoreText(data[0].weekly.updated, data[0].alltime.updated, data[0].weekly.score, data[0].alltime.score)
                        document.body.classList.add('gameover')
                    })
                } else {
                    this.experience.world.text.setGameOverScoreText(0, 0, 'X', 'X')
                    document.body.classList.add('gameover')
                }

            } else {
                // Set target tile color to occupied
                this.activateTile(this.targetTile)
                // Reset color on tile that is no longer occupied
                if(!this.experience.idle && this.experience.showing === this.experience.active) {
                    if(!this.tileHistory.slice(0, this.experience.showing + 1).includes(this.tileHistory[this.experience.showing + 1])) {
                        this.resetTile(this.tileHistory[this.experience.showing + 1])
                    }
                }
            }

        }
    }

    setInitState() {

        for (let i = 0; i < Math.pow(this.experience.gridSize, 2); i++) {
            this.highlightTiles.setUniformAt('opacity', i, 0)
            this.highlightTiles.setUniformAt('diffuse', i, this.floorTileColor)
        }

        this.targetTile = this.floorArray[this.experience.world.snake.targetOffset][this.experience.world.snake.targetOffset]

        this.tileHistory = []
        this.tileHistory.unshift(this.floorArray[this.experience.world.snake.targetOffset - 6][this.experience.world.snake.targetOffset])
        this.tileHistory.unshift(this.floorArray[this.experience.world.snake.targetOffset - 5][this.experience.world.snake.targetOffset])
        this.tileHistory.unshift(this.floorArray[this.experience.world.snake.targetOffset - 4][this.experience.world.snake.targetOffset])
        this.activateTile(this.floorArray[this.experience.world.snake.targetOffset - 4][this.experience.world.snake.targetOffset])
        this.tileHistory.unshift(this.floorArray[this.experience.world.snake.targetOffset - 3][this.experience.world.snake.targetOffset])
        this.activateTile(this.floorArray[this.experience.world.snake.targetOffset - 3][this.experience.world.snake.targetOffset])
        this.tileHistory.unshift(this.floorArray[this.experience.world.snake.targetOffset - 2][this.experience.world.snake.targetOffset])
        this.activateTile(this.floorArray[this.experience.world.snake.targetOffset - 2][this.experience.world.snake.targetOffset])
        this.tileHistory.unshift(this.floorArray[this.experience.world.snake.targetOffset - 1][this.experience.world.snake.targetOffset])
        this.activateTile(this.floorArray[this.experience.world.snake.targetOffset - 1][this.experience.world.snake.targetOffset])
        this.tileHistory.unshift(this.floorArray[this.experience.world.snake.targetOffset][this.experience.world.snake.targetOffset])
        this.activateTile(this.floorArray[this.experience.world.snake.targetOffset][this.experience.world.snake.targetOffset])

        this.experience.world.text.gameOverText.visible = false
        this.experience.world.text.disposeGameOverScoreText()

        gsap.fromTo(this, { floorScale: .95 }, { duration: 1, floorScale: 1, onUpdate: () => {
                let tempIndex = 0
                for (let x = 0; x < this.gridSize; x++) {
                    for (let z = 0; z < this.gridSize; z++) {
                        this.stencilTiles.getMatrixAt(tempIndex, this.dummyMatrix)
                        this.dummyMatrix.decompose(this.dummy.position, this.dummy.quaternion, this.dummy.scale)
                        this.dummy.scale.set(this.floorScale, this.floorScale, this.floorScale)

                        this.dummy.updateMatrix()
                        this.stencilTiles.setMatrixAt( tempIndex, this.dummy.matrix )

                        tempIndex++
                    }
                }
                this.stencilTiles.instanceMatrix.needsUpdate = true
                this.stencilTiles.computeBoundingSphere()
            } })
    }

    update() {
        if(this.experience.started || this.experience.idle) {

            // const headAxis = this.target.x === this.targetHistory[1].x ? 'z' : 'x';

            for (let i = 0; i < this.experience.world.floor.starCount; i++) {
                const i3 = i * 3

                // const offset = headAxis == 'x' ? 0 : 2

                this.starGeo.attributes.position.array[i3] -= this.starSpeed / (10 + Math.abs(this.starGeo.attributes.position.array[i3 + 1]))
                if(this.starGeo.attributes.position.array[i3] < this.experience.gridSize * 1.3 * - 0.5) this.starGeo.attributes.position.array[i3] = this.experience.gridSize * 1.3 * 0.5
                if(this.starGeo.attributes.position.array[i3] > this.experience.gridSize * 1.3 * 0.5) this.starGeo.attributes.position.array[i3] = this.experience.gridSize * 1.3 * - 0.5
            }
            this.starGeo.attributes.position.needsUpdate = true
        }
    }
}